<template>
  <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loaddata="loaddata()"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>

        <!-- hearder -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-account-cog</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="pa-1">
                <span :style="headerPage">&nbsp;{{ $t("admin.setadmin") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card class="elevation-0" :color="color.BG" id="thiscontainersetadmin">
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout row wrap justify-center fill-height>
                <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                  <v-layout row wrap class="pl-3 pr-3">
                    <v-flex lg2>
                      <v-text-field
                        class="pl-1"
                        :label="$t('admin.search')"
                        v-model="search"
                        clearable
                        outlined
                        dense
                        :color="color.theme"
                        append-icon="search"
                        @click:clear="fn_load_staff(), (clicksearch = false)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex lg1 class="pl-1">
                      <v-btn :color="color.theme" :dark="color.darkTheme" @click="fn_search_staff('s'), (clicksearch = true)">{{
                        $t("admin.search")
                      }}</v-btn>
                    </v-flex>
                  </v-layout>
                  <!-- Phonee -->
                  <div v-if="loaddataprogresstable === true">
                    <v-flex lg12 class="ma-2 ml-4 mr-4">
                      <div class="text-center">
                        <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                      </div>
                    </v-flex>
                  </div>
                  <div v-else>
                    <div v-if="resolutionScreen < 500">
                      <v-list two-line class="mb-6 pb-6 elevation-0" v-if="this.total_account !== 0">
                        <v-list-item v-for="item in staff" :key="item.title" style="cursor: pointer;">
                          <v-list-item-content>
                            <v-list-item-title v-if="$t('default') === 'th'" v-text="item.name_th"></v-list-item-title>
                            <v-list-item-title v-else v-text="item.name_eng"></v-list-item-title>
                            <v-list-item-subtitle v-text="item.department_name"></v-list-item-subtitle>
                            <!-- <v-list-item-subtitle v-text="formatdatetime(item.process_dtm)"></v-list-item-subtitle> -->
                          </v-list-item-content>
                          <v-list-item-action>
                            <!-- <v-btn class="elevation-0" fab small @click="opendetaillog = true , detaildata = item"><v-icon >mdi-information</v-icon></v-btn> -->
                            <v-switch
                              @change="fn_confirm_set(item)"
                              v-model="item.status_admin"
                              inset
                              :color="color.theme"
                            ></v-switch>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-list v-else class="mb-6 pb-6 elevation-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                    <!-- Labtop -->
                    <div v-else>
                      <v-data-table
                        :headers="$t('default') === 'en' ? headerEN : headerTH"
                        :items="staff"
                        class="elevation-0"
                        :no-data-text="$t('tablefile.empty')"
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                        <template v-slot:[`header.type`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template v-if="$t('default') === 'th'" v-slot:[`header.name_th`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template v-else v-slot:[`header.name_en`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template v-slot:[`header.department_name`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template v-slot:[`header.status`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>

                        <template v-slot:item="props">
                          <tr>
                            <td width="7%" class="text-center">
                              <v-icon v-if="props.item.status_admin === true" :color="color.theme">mdi-account-star</v-icon>
                              <v-icon v-else>mdi-account</v-icon>
                            </td>
                            <td width="43%" v-if="$t('default') === 'th'">{{ props.item.name_th }}</td>
                            <td width="43%" v-else>{{ props.item.name_eng }}</td>
                            <td width="40%">{{ props.item.department_name }}</td>
                            <td width="10%" class="text-center">
                              <v-switch
                                @change="fn_confirm_set(props.item)"
                                v-model="props.item.status_admin"
                                inset
                                :color="color.theme"
                              ></v-switch>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <br />
                    <!-- <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :color="color.theme"
                      :total-visible="10"
                      @input="clickpagination()"
                    ></v-pagination> -->
                    <v-layout row wrap justify-center>
                      <v-flex xs11 lg11 v-if="pageCount > 0">
                        <v-pagination
                          :total-visible="10"
                          v-model="page"
                          :length="pageCount || 0"
                          :color="color.theme"
                          @input="clickpagination()"
                        ></v-pagination>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card>
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
import gbfGenerate from "../globalFunctions/generateAuthorize";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  components: {
    onebox_admin_toolbar,
  },
  data: function() {
    return {
      clicksearch: false,
      staff: [],
      total_account: 0,
      loaddataprogresstable: false,
      search: [],
      detaildata: {},
      loaddataprogress: true,
      size: 20,
      offset: 0,
      page: 1,
      x: 0,
      y: 0,
      listsize: [10, 20, 50, 100],
      headerTH: [
        {
          text: "#",
          align: "center",
          value: "type",
          width: "7%",
          sortable: false,
        },
        {
          text: "adminlog.name",
          align: "",
          value: "name_th",
          width: "43%",
          sortable: false,
        },
        {
          text: "admin.department",
          align: "",
          value: "department_name",
          width: "43%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "center",
          value: "status",
          width: "7%",
          sortable: false,
        },
      ],
      headerEN: [
        {
          text: "#",
          align: "center",
          value: "type",
          width: "7%",
          sortable: false,
        },
        {
          text: "adminlog.name",
          align: "",
          value: "name_en",
          width: "43%",
          sortable: false,
        },
        {
          text: "admin.department",
          align: "",
          value: "department_name",
          width: "43%",
          sortable: false,
        },
        {
          text: "admin.setting",
          align: "center",
          value: "status",
          width: "7%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "service", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.total_account;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      // console.log(this.color.theme);

      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  methods: {
    //เปลี่ยนหน้า
    clickpagination() {
      console.log(this.search, this.clicksearch);
      if (this.search !== null && this.search.length !== 0 && this.clicksearch === true) {
        this.fn_search_staff("c");
      } else {
        this.fn_load_staff();
      }
    },
    //โหลดรายชื่อพนักงานในบริษัท
  async  fn_load_staff() {
      this.loaddataprogresstable = true;
      this.staff = [];
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        account_id: this.dataAccountId,
        accesstoken: this.dataAccesstoken,
        api_type: "setting_permission_feature",
        limit: cal_limit,
        offset: cal_offset,
        search: ""
      };
      console.log(this.clicksearch);
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(process.env.VUE_APP_SERVICE_MANAGE_ACCOUNT + "/api/v2/select_account_in_business", payload,{
            headers: { Authorization: auth.code },})
        .then((response) => {
          console.log("get", response);
          if (response.data.status === "OK") {
            this.total_account = response.data.total_account;
            for (let i = 0; i < response.data.account.length; i++) {
              let datastaff = {};
              datastaff["staff_id"] = response.data.account[i]["id"];
              datastaff["name_eng"] = response.data.account[i]["name_eng"];
              datastaff["name_th"] = response.data.account[i]["name_th"];
              datastaff["department_name"] = response.data.account[i]["department"];
              datastaff["status_admin"] = this.settoboolean(response.data.account[i]["admin_business_status"]);
              this.staff.push(datastaff);
              this.loaddataprogresstable = false;
            }
          } else {
             Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
            this.loaddataprogresstable = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loaddataprogresstable = false;
        });
    },
    //ค้นหาชื่อพนักงาน
   async fn_search_staff(parameter) {
      this.loaddataprogresstable = true;
      this.staff = [];
      let cal_limit = this.size;
      let cal_offset;
      if (parameter === "c") {
        cal_offset = this.size * (this.page - 1);
      } else {
        cal_offset = 0;
        this.page = 1;
      }
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        search: this.search,
        limit: cal_limit,
        offset: cal_offset,
      };
      //console.log(payload);
      //console.log(this.clicksearch);
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/search_account_in_business", payload,{
            headers: { Authorization: auth.code },})
        .then((response) => {
          console.log("get", response);
          if (response.data.status === "OK") {
            this.total_account = response.data.total;
            if(response.data.total === 0){
             this.staff = [];
             this.loaddataprogresstable = false;
            }else{
            for (let i = 0; i < response.data.account.length; i++) {
              let datastaff = {};
              datastaff["staff_id"] = response.data.account[i]["id"];
              datastaff["name_eng"] = response.data.account[i]["name_eng"];
              datastaff["name_th"] = response.data.account[i]["name_th"];
              datastaff["department_name"] = response.data.account[i]["department"];
              datastaff["status_admin"] = this.settoboolean(response.data.account[i]["admin_business_status"]);
              this.staff.push(datastaff);
              this.loaddataprogresstable = false;
            }
            }
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
            this.loaddataprogresstable = false;
          }
          console.log(this.staff);
        })
        .catch((error) => {
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loaddataprogresstable = false;
        });
    },
    //ยืนยันการตั้งค่า แอดมิน
    fn_confirm_set(item) {
      if (item.status_admin === true) {
        Swal.fire({
          title: this.$t("toast.confirmsetadmin"),
          text:
            this.$t("toast.textsetadmin_1") + " " + this.$t("defalut") === "th"
              ? item.name_th
              : item.name_eng + " " + this.$t("toast.textsetadmin_2"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonColor: this.color.theme,
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("admin.submit"),
        }).then((result) => {
          if (result.isConfirmed) {
            this.fn_set_admin(item);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            let objIndex = this.staff.findIndex((obj) => obj.staff_id === item.staff_id);
            console.log(objIndex, this.staff[objIndex]);
            this.staff[objIndex]["status_admin"] = !item.status_admin;
          }
        });
      } else {
        Swal.fire({
          title: this.$t("toast.confirmsetadmin"),
          text: this.$t("toast.textsetadmin_3") + " " + item.name_th + " " + this.$t("toast.textsetadmin_2"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonText:this.$t("admintoast.cancel"),
          confirmButtonColor: this.color.theme,
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("admin.submit"),
        }).then((result) => {
          if (result.isConfirmed) {
            this.fn_set_admin(item);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            let objIndex = this.staff.findIndex((obj) => obj.staff_id === item.staff_id);
            console.log(objIndex, this.staff[objIndex]);
            this.staff[objIndex]["status_admin"] = !item.status_admin;
          }
        });
      }
    },
    //ตั้งค่า แอดมิน
    async fn_set_admin(item) {
      console.log("sett", item);
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        account_admin: this.dataAccountId,
        account_id: item.staff_id,
        status_admin: this.settostring(item.status_admin),
      };
      //console.log(payload);
      let auth = await gbfGenerate.generateToken();
      console.log(auth);
     await this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/v2/setting_admin_business", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log("set", response);
          if (response.data.status === "OK") {
            this.fn_load_staff();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
        });
    },
    //convert string to boolean
    settoboolean(parameter) {
      if (parameter === "True" || parameter === "False" || parameter === "") {
        if (parameter === "True") {
          return true;
        } else if (parameter === "False") {
          return false;
        } else {
          return "";
        }
      }
    },
    //convert boolean to string
    settostring(parameter) {
      if (parameter === true || parameter === false || parameter === "") {
        if (parameter === true) {
          return "True";
        } else if (parameter === false) {
          return "False";
        } else {
          return "";
        }
      }
    },
  },
  mounted() {
    this.fn_load_staff();
    this.loaddataprogress = false;
  },
};
</script>
<style>
#thiscontainersetadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>
